import React, { useEffect, useState } from 'react';
import { ChatWidget } from '@s7-chat/s7-chat-widget';
import { useDetectKeyboardAndroid } from '../../hooks/useDetectKeyboardAndroid';
import { isEmpty } from 'lodash';

export const ZendeskChat = (props) => {
  const { profile, minimizeChatHandle, isChatOpen, isExperiment, checkChatValidation, isChatEnabled } = props;
  const [application, setApplication] = useState(null);

  const [isFirstMount, setFirstMount] = useState(true);

  const detectProfi = () => {
    if (isEmpty(profile)) {
      return null;
    }
    const corporateId = profile?.b2b?.corporateId || '';
    const externalId = profile?.b2b?.externalId || '';

    const s7PageInit = typeof (window.s7PageInit) !== 'undefined' ? window.s7PageInit : {};
    if (s7PageInit.settings) {
      if (s7PageInit.settings.chat && s7PageInit.settings.chat.profiChatForB2b === false) {
        return null;
      }
    }
    if (!!corporateId) {
      const corporateIdMatch = corporateId.startsWith('QUW');
      if (corporateIdMatch) {
        return 's7profi';
      }
    }
    if (!!externalId) {
      const externalIdMatch = externalId.startsWith('QY');
      if (externalIdMatch) {
        return 's7profi';
      }
    }
    return null;
  }

  useEffect(() => {
    setApplication(detectProfi())
  }, [profile]);

  useEffect(() => {
    if (!isFirstMount) {
      checkChatValidation();
    } else {
      setFirstMount(false)
    }
  }, [window.location.href]);

  const isKeyboardOpen = useDetectKeyboardAndroid();
  if (isKeyboardOpen || !isChatEnabled) {
    return (
      <></>
    )
  }

  const { phone, ...rest } = profile;

  return (
    <ChatWidget
      application={application}
      profile={{
        phone: phone?.phoneString,
        ...rest
      }}
      minimizeChatHandle={minimizeChatHandle}
      isChatOpen={isChatOpen}
      isExperiment={isExperiment}
      styles={{
        zIndexButton: 200
      }}
    />
  );
};