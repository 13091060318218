var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AnalyticsManager, createPluginGA, createPluginYM, } from "@s7/analytics";
var createAppFlowContext = function () {
    var mainPaths = ["/", "/ru/", "/en/", "/zh/"];
    if (mainPaths.indexOf(window.location.pathname) > -1) {
        return "main";
    }
    return null;
};
var flow = createAppFlowContext();
var createAppUserIdContext = function () {
    var profileId;
    function set(id) {
        profileId = id;
    }
    function get() {
        return profileId;
    }
    return {
        get: get,
        set: set,
    };
};
export var userId = createAppUserIdContext();
export var analytics = AnalyticsManager({
    transform: function (eventName, payload) { return (__assign(__assign({ application: "portal", event_name: eventName }, (flow && { flow: flow })), payload)); },
    plugins: [
        createPluginGA({
            initialize: { containerId: process.env.REACT_APP_GA_ID },
        }),
        createPluginYM({
            initialize: function () { return ({
                counterId: process.env.REACT_APP_YANDEX_ID,
                instanceKey: "ym",
                status: function () { return true; },
            }); },
            transform: function (eventName, payload) { return (__assign({ user_id: userId.get() }, payload)); },
        }),
    ],
});
